<template>
  <div>
    <h1>{{title}}</h1>
    <div class="error" v-if="error">
      <span><h3>Error Loading Data :(</h3></span>
    </div>
    <div class="container">
      <div class="loading" v-if="loading">
        <h2>Loading...</h2>
      </div>
      <Snek
        v-if="!state.crispy"
        v-bind:points="snekData ? snekData.points : []"
        v-bind:snek-colour="snekData ? snekData.snekColour : 'black'"/>
      <Crispy
        v-if="state.crispy"
        v-bind:points="snekData ? snekData.points : []"
        v-bind:snek-colour="snekData ? snekData.snekColour : 'black'"/>
      <br />
      <ul class="controls">
        <li><button @click="toggleCrispiness()">{{buttonTitle}}</button></li>
        <li><button @click="refetch()">Reload</button></li>
      </ul>
    </div>
  </div>
</template>

<script>
import { useQuery, useResult } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { computed, reactive } from 'vue';

import Snek from './Snek.vue';
import Crispy from './CrispySnek.vue';

export default {
  setup() {
    const {
      result, loading, error, refetch,
    } = useQuery(gql`
      query {
        getSnek(team: CGY) {
          snekColour
          points
        }
      }
    `);

    const snekData = useResult(result);
    const state = reactive({ crispy: false });
    const toggleCrispiness = () => {
      state.crispy = !state.crispy;
    };
    const title = computed(() => (state.crispy ? 'Crispy crispy snek!' : 'Snek snek snek!'));
    const buttonTitle = computed(() => (state.crispy ? 'Normal' : 'Crisp it'));

    return {
      snekData,
      loading,
      error,
      refetch,
      state,
      toggleCrispiness,
      title,
      buttonTitle,
    };
  },
  components: {
    Snek,
    Crispy,
  },
};
</script>

<style>
  .error {
    background-color: lightcoral;
    padding: 0.25em;
    color: darkred;
  }

  .loading {
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    background-color: darkgray;
    z-index: 10;
  }

  .container {
    position: relative;
  }

  ul.controls > li {
    display: inline-block;
    margin: 10px;
  }
</style>
