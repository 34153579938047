import { createApp, provide, h } from 'vue';
import { createHttpLink, InMemoryCache, ApolloClient } from '@apollo/client/core';
import { DefaultApolloClient } from '@vue/apollo-composable';

import App from './App.vue';

const httpLink = createHttpLink({
  uri: 'https://snek-api.tscho.ca',
});

const cache = new InMemoryCache();

const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
});

createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient);
  },
  render: () => h(App),
}).mount('#app');
