<template>
  <canvas ref="chart" height="560" width="960"></canvas>
</template>

<script>
const WIDTH = 900;
const HEIGHT = 500;

// const ABS_WIDTH = WIDTH + 60;
// const ABS_HEIGHT = HEIGHT + 60;

const MAX_POINTS = 120;
const BASELINE_POINTS = 96;

const Y_ORIGIN = HEIGHT + 30;
const X_ORIGIN = 30;
const ORIGIN = [X_ORIGIN, Y_ORIGIN];

const getXCoord = (game) => X_ORIGIN + (game / 82) * WIDTH;

const getYCoord = (points) => Y_ORIGIN - (points * HEIGHT) / MAX_POINTS;

const getCoords = (x, y) => [getXCoord(x), getYCoord(y)];

export default {
  name: 'NormalSnek',
  props: {
    points: Array,
    snekColour: String,
  },
  watch: {
    points() {
      this.draw();
    },
  },
  methods: {
    draw() {
      const baseline = new Path2D();
      baseline.moveTo(...ORIGIN);
      baseline.lineTo(...getCoords(82, BASELINE_POINTS));

      const snek = new Path2D();
      snek.moveTo(...ORIGIN);
      this.points.forEach((points, game) => snek.lineTo(...getCoords(game, points)));

      const xAxis = new Path2D();
      xAxis.moveTo(...ORIGIN);
      xAxis.lineTo(...getCoords(82, 0));

      const ctx = this.$refs.chart.getContext('2d');
      ctx.clearRect(0, 0, this.$refs.chart.width, this.$refs.chart.height);

      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.font = '12px sans-serif';
      ctx.strokeStyle = 'black';
      ctx.lineWidth = 1;

      for (let i = 0; i <= 82; i += 1) {
        const [x, y] = getCoords(i, 0);
        xAxis.moveTo(x, Y_ORIGIN + 8);
        xAxis.lineTo(x, Y_ORIGIN - HEIGHT);

        if (i % 2 === 0) {
          ctx.fillText(String(i), x, y + 20);
        }
      }

      const yAxis = new Path2D();
      yAxis.moveTo(...ORIGIN);
      yAxis.lineTo(...getCoords(0, MAX_POINTS));

      for (let i = 0; i <= MAX_POINTS; i += 2) {
        const [x, y] = getCoords(0, i);
        yAxis.moveTo(x, y);
        if (i % 10 === 0 || i === BASELINE_POINTS) {
          yAxis.moveTo(X_ORIGIN - 8, y);
          yAxis.lineTo(X_ORIGIN + WIDTH, y);
          ctx.fillText(String(i), x - 20, y);
        } else {
          yAxis.lineTo(X_ORIGIN - 4, y);
        }
      }

      ctx.lineWidth = 0.5;
      ctx.strokeStyle = 'gray';
      ctx.stroke(xAxis);
      ctx.stroke(yAxis);

      ctx.lineWidth = 2;
      ctx.stroke(baseline);

      ctx.lineWidth = 3;
      ctx.strokeStyle = this.snekColour || 'red';
      ctx.stroke(snek);
    },
  },
  mounted() {
    this.draw();
  },
};
</script>

<style>
</style>
